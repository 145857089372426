<template>
  <b-card v-if="loading">
    <IndexFormModal
      ref='indexFormModal'
      :role="{title:'พันธมิตร'}"
      @reload="QUERY_RESULTS()"
    />
    <ClientCreditModal ref="clientCreditModal">
    </ClientCreditModal>

    <h5 class="mb-2">
      ข้อมูลส่วนตัว
    </h5>

    <h5 class="mb-2">
      ชื่อ - สกุล : {{_fullName}}
    </h5>
    <h5 class="mb-2">
      ชื่อผู้ใช้ : {{_email}}
    </h5>
    <h5 class="mb-2">
      ยอดขาย : {{withCommas(_saleTotal)}}
    </h5>
    <h5 class="mb-2">
      เครดิตคงเหลือ : {{withCommas(_creditSandbox)}}
    </h5>
    <b-row>
      <b-col
        sm="12"
        class="text-right"
      >
        <!-- button manage credit -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-info"
          pill
          @click="$refs.clientCreditModal.show(results.userInfo)"
          class="mr-1"
          v-if="_isClient"
        >
          เครดิต
        </b-button>
        <!-- end -->

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-warning"
          pill
          @click="$refs.indexFormModal.show(results.userInfo)"
        >
          แก้ไขข้อมูล
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import { mapState, mapMutations, mapGetters } from 'vuex'
import IndexFormModal from '@/views/apps/admins/userManagement/components/index/IndexFormModal.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters('dashboardDataStore', ['_isClient']),
    ...mapState('dashboardDataStore', ['results', 'loading']),

    _fullName() {
      return this.results.userInfo?.user_profile_data?.name
    },
    _email() {
      return this.results.userInfo?.email ?? ''
    },
    _saleTotal() {
      return this.results.userInfo?.credit_balance
    },
    _creditSandbox() {
      return this.results.userInfo?.credit_sandbox
    },
  },
  components: {
    BCard,
    BCardText,
    IndexFormModal,
    ClientCreditModal: () => import('./Clients/ClientCreditModal.vue'),
  },
  methods: {
    ...mapMutations('dashboardDataStore', ['QUERY_RESULTS']),
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
